.checkout-panel .column {
  .checkout-panel__content {
    .email-promotions.pc-hidden {
      display: block !important;
    }
  }
}

.site-header {
  .user-loyalty {
    &__points-join {
      display: none;
    }
    &__user {
      display: none;
    }
  }
}

.elc-user-state-anonymous {
  .site-header {
    .user-loyalty {
      &__points-join {
        display: inline;
      }
    }
    &__promo {
      .guest-content {
        display: inline-block;
      }
    }
  }
}

.elc-user-state-logged-in {
  .site-header {
    .user-loyalty {
      &__user {
        display: inline-block;
      }
    }
  }
}

#confirm {
  .checkout--confirmation-page {
    .birth_date_reg {
      overflow: unset;
    }
  }
  .checkout-panel {
    border-top: 1px solid $light-gray;
    border-bottom: none;
    .refer-friend {
      &__image-section {
        text-align: center;
        padding: 50px 0 25px 0;
        @include breakpoint($landscape-up) {
          width: 50%;
          float: $ldirection;
          padding: 30px 0 10px 0;
        }
      }
      &__content {
        text-align: center;
        padding-bottom: 30px;
        @include breakpoint($landscape-up) {
          text-align: left;
          width: 50%;
          float: $rdirection;
          padding: 89px 0 40px 30px;
        }
        a {
          color: $white;
          background: $color-off-black;
          padding-top: 11px;
          padding-bottom: 7px;
        }
      }
      &__offer-content {
        font-size: 18px;
        font-weight: bold;
        line-height: 1.2;
        @include breakpoint($landscape-up) {
          font-size: 22px;
          margin-bottom: 5px;
        }
      }
      &__reward-content {
        font-size: 14px;
        margin-bottom: 17px;
      }
    }
    .checkout--confirmation-page {
      .registration-content__create-account {
        top: 50%;
      }
    }
  }
}

.social-login {
  &__container {
    display: inline-flex;
    flex-direction: column;
  }
  &__divider {
    order: 1;
  }
  &__email-opt-in {
    order: 3;
    padding-bottom: 20px;
  }
  &__terms {
    order: 2;
  }
  &__info {
    order: 4;
  }
  .fb-login-button {
    order: 5;
  }
}

.legal-copy {
  padding-top: 20px;
}

.underline {
  text-decoration: underline;
}

.new-account-content {
  .email_promotions {
    .link {
      display: none;
    }
  }
}
