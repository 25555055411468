.slide-comparison {
  height: auto;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  cursor: col-resize;
  img {
    display: block;
    width: 100%;
    height: 100%;
    max-width: none;
  }
  .resized {
    position: absolute;
    top: 0;
    #{$ldirection}: 0;
    height: 100%;
    width: 50%;
    overflow: hidden;
  }
  .divider {
    position: absolute;
    #{$ldirection}: 50%;
    top: 0;
    bottom: 0;
    width: 2px;
    margin-#{$ldirection}: -1px;
    background: $color-brown;
  }
  .notice {
    position: absolute;
    #{$ldirection}: 50%;
    top: 0;
    width: 200px;
    margin-#{$ldirection}: -100px;
    font-size: 20px;
    text-align: center;
    opacity: 1;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  &:hover {
    .notice {
      opacity: 0;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
    .divider:after {
      top: 46px;
      width: 48px;
      height: 48px;
      margin: -24px 0 0 -24px;
    }
  }
}
.product-full__carousel__video {
  .icon--video-play {
    display: none;
  }
}

.question-processed {
  .pr-qa-snippet-container {
    .pr-ask-question-link.count-rendered,
    .pr-snippet-qa-answer-link.count-rendered {
      margin-#{$ldirection}: 0;
    }
  }
}

.question-processed.pr-ask-link-first-question {
  .pr-qa-snippet-container {
    .pr-snippet-qa-answer-link.count-rendered {
      margin-#{$ldirection}: 40px;
    }
  }
}

.quiz-landing-page-formatter {
  &__main-block {
    padding-top: 200px;
    background-color: $color-beige;
    @include breakpoint($medium-up) {
      padding-top: 0;
    }
  }
}

.pop-widget {
  &.show-widget {
    .SGBasket-widget {
      bottom: 55px;
    }
  }
  &.hide-widget {
    .SGBasket-widget {
      .SGBasketWrapper {
        .SGBasketButton {
          bottom: -40px;
        }
      }
    }
  }
}

// Hide SMS optin on Account profile page.
.profile-page {
  .sms-promotions,
  .pp-link__sms_optin {
    display: none;
  }
}
