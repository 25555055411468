// To hide the Navigation bar during loading of salon page
.site-header__nav {
  .site-header__menu {
    .site-header__menu-list--desktop {
      .site-header__menu-logo.header-mobile-hidden {
        display: none;
        margin-top: 15px;
        @include breakpoint($portrait-up) {
          display: block;
        }
        .salon-takeover & {
          display: block;
        }
      }
    }
  }
}
