.page-wrapper,
header {
  position: relative;
  &.fixed {
    position: fixed;
  }
}

body.fullscreen-enabled {
  overflow: hidden;
  position: fixed;
  .content-dim {
    visibility: visible;
    opacity: 1;
    z-index: 9999;
  }
  #colorbox {
    z-index: 99999;
  }
}

nav.breadcrumbs {
  background-color: $white;
  .breadcrumbs__level {
    @if $typography_cr24 == true {
      @include body-r;
    } @else {
      font-size: 15px;
    }
    a {
      @if $typography_cr24 == true {
        @include body-r;
      } @else {
        font-size: 15px;
      }
      &:hover {
        color: $color-gray;
        text-decoration: underline;
      }
    }
    &:nth-last-child(2) {
      a {
        &:hover {
          color: $color-off-black;
          text-decoration: none;
        }
      }
    }
  }
}

.sku-menu {
  width: 100%;
  &__container {
    margin: 1em 0;
    .product-brief__footer & {
      @include breakpoint($landscape-up) {
        margin-bottom: 0;
      }
    }
  }
}

.product-sku-price {
  @if $pdp_modernized_design == false {
    width: 100%;
  }
}

.product-brief__price2,
.product-sku-price2__value {
  text-decoration: line-through;
}

.product-brief {
  .product-sku-price {
    @if $typography_cr24 == true {
      @include body-r;
    }
  }
  .product-sku-price:not(.has-sale) {
    .product-price-points-display {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  .product-price-points-display {
    line-height: 1.2;
  }
  .product-sku-price__value,
  .product-sku-unit-price__value {
    line-height: 2;
  }
  .product-sku-price__value-link,
  .product-sku-price__value_red-link {
    display: block;
    height: 100%;
    text-decoration: none;
  }
  .product-sku-price__value-link {
    &:hover {
      color: $color-off-black;
    }
  }
}

.on-sale,
.has-sale .product-sku-price__value,
.cart-items__item--product-price-discount {
  color: $red;
}

.cart-items__item--product-price,
.cart-items__item--product-price-discount {
  @include breakpoint($medium-down) {
    display: inline;
  }
}

// The sku-unit-price font-size is 2px smaller than the sku-price.
.product-sku-unit-price__value {
  float: #{$ldirection};
  display: block;
  width: 100%;
  font-size: 12px;
  @include breakpoint($landscape-up) {
    font-size: 14px;
  }
}

.product-full--enhancement {
  .carousel-controls {
    top: 36%;
    @include breakpoint($landscape-up) {
      top: 50%;
    }
    .slick-next {
      #{$rdirection}: 0;
    }
    .slick-prev {
      #{$ldirection}: 0;
    }
    .slick-arrow {
      min-width: 0;
      height: 96px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      @include breakpoint($landscape-up) {
        width: inherit;
        justify-content: normal;
        align-items: normal;
        height: 50px;
      }
      &:before {
        width: 15px;
        height: 15px;
        #{$ldirection}: 50%;
        margin-#{$ldirection}: -8px;
      }
    }
  }
  .carousel-dots {
    margin-top: 25px;
  }
  .slick-dots {
    bottom: 0;
    button {
      &:before {
        width: 8px;
        height: 8px;
        border: none;
        background: $color-lighter-gray;
      }
    }
    .slick-active {
      button {
        &:before {
          background: $color-dark-gray;
        }
      }
    }
  }
  .product-full {
    &__image-carousel {
      .carousel-controls {
        @include breakpoint($landscape-up) {
          display: flex;
          width: 83%;
          justify-content: center;
          top: auto;
          bottom: $full-screen-outer-padding;
          overflow: hidden;
          min-width: 100px;
          height: $full-screen-carousel-button-size;
          z-index: 1;
        }
        .slick-arrow {
          width: 60px;
          @include breakpoint($landscape-up) {
            position: relative;
            width: 25px;
          }
          &:before {
            margin-#{$ldirection}: -5px;
            @include breakpoint($landscape-up) {
              width: 9px;
              height: 9px;
            }
          }
        }
        .slick-prev {
          &:before {
            @include breakpoint($landscape-up) {
              height: $full-screen-carousel-button-size;
              line-height: $full-screen-carousel-button-size;
            }
          }
          @include breakpoint($landscape-up) {
            #{$ldirection}: 0;
            min-width: 0;
          }
        }
        .slick-count {
          @if $typography_cr24 == true {
            @include body-xs;
            @include breakpoint($landscape-up) {
              display: block;
              line-height: $full-screen-carousel-button-size;
            }
          } @else {
            @include breakpoint($landscape-up) {
              display: block;
              height: $full-screen-carousel-button-size;
              line-height: $full-screen-carousel-button-size;
              font-size: 16px;
            }
          }
          display: none;
        }
        .slick-next {
          &:before {
            @include breakpoint($landscape-up) {
              height: $full-screen-carousel-button-size;
              line-height: $full-screen-carousel-button-size;
            }
          }
          @include breakpoint($landscape-up) {
            #{$rdirection}: 0;
            min-width: 0;
          }
        }
      }
    }
    &__carousel__slides {
      &.single-carousel {
        transform: translate3d(-25%, 0, 0);
        @include breakpoint($landscape-up) {
          transform: none;
        }
      }
      img {
        margin: auto;
        max-width: 100%;
        height: 295px;
        @if $pdp_modernized_design == false {
          width: 175px;
        }
        @include breakpoint($landscape-up) {
          margin-bottom: 60px;
          width: auto;
          max-width: 100%;
          height: auto;
          @if $pdp_modernized_design == false {
            float: #{$ldirection};
          }
        }
      }
      &.slick-slider {
        -ms-touch-action: auto;
        touch-action: auto;
      }
    }
    &__image-wrapper {
      margin-top: 10px;
      max-height: 300px;
      width: 100%;
      @include breakpoint($landscape-up) {
        float: #{$ldirection};
        width: 50%;
      }
      img {
        max-height: 300px;
        @include breakpoint($landscape-up) {
          max-height: 500px;
        }
      }
    }
    &__breadcrumb {
      display: block;
      @if $pdp_modernized_design == true {
        color: $black;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 95%;
        font-size: 16px;
        @include breakpoint($portrait-up) {
          margin-top: 0;
          margin-bottom: 15px;
        }
        @include breakpoint($landscape-up) {
          margin-top: 25px;
        }
        a {
          color: $black;
        }
      }
    }
    &__details {
      @if $pdp_modernized_design == true {
        margin-top: 30px;
        @include breakpoint($portrait-up) {
          margin-top: 90px;
        }
      } @else {
        margin-top: 90px;
      }
      @include breakpoint($landscape-up) {
        width: 50%;
        margin-top: auto;
      }
    }
    &__image-thumbs {
      .carousel-controls {
        top: auto;
        height: 100%;
      }
      .slick-prev {
        #{$ldirection}: 0;
        &::before {
          @include svg-icon-inline-mask('caret--up');
        }
      }
      .slick-next {
        position: absolute;
        top: inherit;
        bottom: -30px;
        #{$rdirection}: 0;
        &::before {
          @include svg-icon-inline-mask('caret--down');
        }
      }
    }
    &__image-thumb {
      height: 92px;
    }
    &__size-bag-container {
      @if $pdp_modernized_design == true {
        margin-top: 16px;
        @include breakpoint($landscape-up) {
          margin-top: 24px;
        }
      } @else {
        margin-top: 24px;
      }
    }
    &__sku-select-container {
      display: inline-block;
      width: 100%;
      @if $pdp_modernized_design == true {
        margin-top: 16px;
        @include breakpoint($medium-up) {
          margin-top: 24px;
        }
      } @else {
        margin-top: 15px;
      }
      .select-box {
        top: 2px;
        text-align: center;
      }
      .select-box__label,
      .select-box__label:after,
      .select-box__option {
        height: 42px;
        line-height: 42px;
      }
      .select-box__option:not(:first-child) {
        border-top: 1px solid $color-off-black;
      }
      .select-box__options {
        top: 41px;
      }
      .sku-menu-chip__option {
        .sku-menu-chip__price {
          @if $typography_cr24 == true {
            @include body-r;
          }
        }
      }
    }
    &__add-bag-wrapper {
      display: flex;
      justify-content: flex-start;
    }
    &__quantity-select {
      @if $pdp_modernized_design == true {
        height: 48px;
        width: 125px;
      } @else {
        height: 43px;
      }
      margin-top: 1px;
      margin-#{$rdirection}: 5px;
      font-size: 16px;
      .product-qty-select {
        @if $pdp_modernized_design == true {
          height: 48px;
        } @else {
          height: 43px;
        }
        display: flex;
        align-items: center;
        min-width: 60px;
        padding: 0 4px;
        border: 1px solid rgba($black, 0.3);
        button,
        .product-qty-select-amount {
          flex-grow: 1;
          text-align: center;
          text-decoration: none;
        }
        .product-qty-select-amount {
          @if $typography_cr24 == true {
            @include body-l;
          }
        }
        button {
          &:disabled {
            cursor: not-allowed;
            pointer-events: none;
            opacity: 0.3;
          }
          svg {
            width: 7px;
            margin: 0 auto;
          }
        }
        @include breakpoint($mobile-only) {
          min-width: 90px;
          @if $pdp_modernized_design == true {
            padding-inline-start: 8px;
            width: 125px;
            font-size: 18px;
            color: $black;
          } @else {
            padding: 0 10px;
            margin-#{$ldirection}: 11px;
          }
        }
      }
      h4 {
        font-size: 13px;
        font-weight: 500;
      }
      @include breakpoint($mobile-only) {
        @if $pdp_modernized_design == false {
          width: 100%;
          margin: 10px 0 0;
        }
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
    &__add-bag-container {
      flex: 1;
      .product__button--add-to-bag,
      .product__button--loading {
        height: 48px;
        margin-top: 0;
        width: 100%;
      }
    }
    &__actions {
      padding-top: 14px;
      padding-bottom: 4px;
      @include breakpoint($landscape-up) {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
  .sku-menu__container {
    @if $pdp_modernized_design == true {
      margin-top: 0;
    } @else {
      margin-top: 20px;
    }
    margin-bottom: 0;
    @include breakpoint($landscape-up) {
      margin-top: 0;
    }
  }
  .spp-content .product-add-to-waitlist {
    @include breakpoint($landscape-up) {
      height: 43px;
      line-height: 43px;
    }
  }
  .product__button--loading {
    @include breakpoint($landscape-up) {
      min-width: 240px;
      height: 48px;
      line-height: 43px;
      padding: 0;
    }
  }
  &.fullscreen-enabled {
    .product-full {
      &__image-wrapper {
        img {
          max-height: none;
        }
      }
    }
  }
}

.spp-content {
  .product__price-installment {
    .afterpay-paragraph {
      text-align: $ldirection;
      @if $pdp_modernized_design == true {
        font-size: 12px;
        display: flex;
        gap: 2px;
        margin: 0;
        .afterpay-text1 {
          opacity: 0.64;
          font-style: italic;
          color: $black;
          padding-inline-end: 2px;
        }
        .afterpay-text2 {
          @if $typography_cr24 == true {
            @include text-disclaimer;
          }
          opacity: 0.64;
          font-style: italic;
          margin: 0 4px;
        }
        .afterpay-logo-link {
          display: flex;
          margin-top: 2px;
          justify-content: space-between;
          flex: 1;
          @include breakpoint($landscape-up) {
            justify-content: flex-start;
            gap: 8px;
          }
          img {
            width: 77px;
            padding-bottom: 10px;
          }
          a {
            font-size: 18px;
            color: $color--textColor-secondary !important;
            line-height: 0.5;
          }
        }
        .afterpay-link {
          @if $typography_cr24 == true {
            @include text-disclaimer;
          }
        }
        img {
          width: 77px;
        }
      }
    }
  }
}

.product-grid--filtered {
  .mix {
    display: none;
  }
}

// Show no matches when none found.
.mixitup-initialized.fail + .no-matches {
  display: block;
  text-align: center;
  padding: 100px 0;
}
