///
/// Checkout pages styling
///

// Common styles
.viewcart {
  .checkout-panel {
    &__header,
    &__heading {
      .mobile-hidden {
        @include breakpoint($landscape-up) {
          display: inline-block !important;
        }
      }
    }
    .checkout-buttons-content {
      .button--disabled {
        background: $color-light-gray;
        border: 1px solid $color-light-gray;
        cursor: default;
        &.paypal-checkout {
          opacity: 0.4;
        }
      }
    }
    &__content {
      &--carbon-offset {
        font-weight: 700;
      }
    }
    &__top-viewcart-buttons {
      .carbon-offset {
        &--disclaimer {
          display: none;
        }
      }
    }
  }
}

// Acomm - over-ride the _footer.scss and Enabling the footer for US
.site-footer {
  &__primary {
    .salon-takeover & {
      display: block;
    }
  }
}

.checkout {
  &__carbon-offset {
    margin: 1em;
    font-weight: 700;
  }
}
